import React from "react";
import { isMobile } from "react-device-detect";
import useUrl from "../utils/client";

const Contentfacebook = () => {
  const [image1, done1] = useUrl("2CHrH6JtZAS1u41sV9bG6a");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "32px", width: "32px" }}></div>;
    return <img src={image1} alt="facebook" />;
  };
  return <>{renderPosts()}</>;
};
const Contentlinkedin = () => {
  const [image1, done1] = useUrl("600jWJJyBzt7pBqlSJVdUQ");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "32px", width: "32px" }}></div>;
    return <img src={image1} alt="linkedin" />;
  };
  return <>{renderPosts()}</>;
};
const Contentinstagram = () => {
  const [image1, done1] = useUrl("3ohUf9VGbIzUDlBcIsvSTK");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "32px", width: "32px" }}></div>;
    return <img src={image1} alt="instagram" />;
  };
  return <>{renderPosts()}</>;
};
const Contentwhatsapp = () => {
  const [image1, done1] = useUrl("22CvyGCuXwE5cgXDZfTczj");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "32px", width: "32px" }}></div>;
    return <img src={image1} alt="whatsapp" />;
  };
  return <>{renderPosts()}</>;
};

const renderContent = () => {
  if (isMobile) {
    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="social-icon"
        href="https://wa.me/51990016463"
      >
        <Contentwhatsapp />
      </a>
    );
  }
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      className="social-icon"
      href="https://web.whatsapp.com/send?phone=51990016463"
    >
      <Contentwhatsapp />
    </a>
  );
};

const Social = () => {
  return (
    <div className="purple-contact flex-item">
      <div className="contact-footer">
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="social-icon"
          href="https://www.facebook.com/POP-UP-Marketing-Integral-105320227721374/"
        >
          <Contentfacebook />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="social-icon"
          href="https://www.instagram.com/popupdespiertatusideas/"
        >
          <Contentinstagram />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="social-icon"
          href="https://pe.linkedin.com/company/pop-up-marketing-integral"
        >
          <Contentlinkedin />
        </a>
        {renderContent()}
      </div>
    </div>
  );
};

export default Social;
