import React, { Component } from "react";
import { FaAlignRight, FaAlignJustify } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import useUrl from "../utils/client";

const ContentLogo = () => {
  const [image1, done1] = useUrl("4uKPSffgGRPmvcjOphFbvP");
  const renderPosts = () => {
    if (done1) return <p></p>;
    return <img src={image1} alt="Pop Up" />;
  };
  return <>{renderPosts()}</>;
};

export default class Navbar extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <div className="nav-logo-container">
              <Link className="nav-phone-act" to="/">
                <ContentLogo />
              </Link>
              <a className="nav-phone-des" href="/">
                <ContentLogo />
              </a>
            </div>
            <button
              type="button"
              className="nav-btn"
              aria-label="Dropdown navigation"
              onClick={this.handleToggle}
            >
              {this.state.isOpen ? (
                <FaAlignJustify className="nav-icon" />
              ) : (
                <FaAlignRight className="nav-icon" />
              )}
            </button>
          </div>
          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="nav-link-yellow-selected"
                className="nav-phone-act nav-link-yellow"
              >
                Inicio
              </NavLink>
              <a href="/" className="nav-phone-des">
                Inicio
              </a>
            </li>
            <li className="nav-unidades">
              <NavLink
                className="nav-phone-act navlink-unidades"
                exact
                to="/merchandising"
                activeClassName="nav-link-yellow-selected"
              >
                Unidades
              </NavLink>
              <div className="nav-phone-des navlink-unidades">Unidades</div>
              <div className="nav-dropdown">
                <Link
                  className="nav-phone-act nav-dropdown-child"
                  to="/merchandising"
                >
                  Merchandising
                </Link>
                <Link className="nav-phone-act nav-dropdown-child" to="/offset">
                  Offset
                </Link>
                <Link className="nav-phone-act nav-dropdown-child" to="/visual">
                  Visual
                </Link>
                <a href="/merchandising" className="nav-phone-des">
                  Merchandising
                </a>
                <a href="/offset" className="nav-phone-des">
                  Offset
                </a>
                <a href="/visual" className="nav-phone-des">
                  Visual
                </a>
              </div>
            </li>
            <li>
              <NavLink
                exact
                to="/tendencia"
                activeClassName="nav-link-yellow-selected"
                className="nav-phone-act nav-link-yellow"
              >
                Tendencia
              </NavLink>
              <a href="/tendencia" className="nav-phone-des">
                Tendencia
              </a>
            </li>
            <li>
              <a
                href="https://catalogo.popup.pe/"
                className="nav-phone-act nav-link-yellow"
                target="_blank"
                rel="noopener noreferrer"
              >
                Catálogo
              </a>
              <a
                href="https://catalogo.popup.pe/"
                className="nav-phone-des"
                target="_blank"
                rel="noopener noreferrer"
              >
                Catálogo
              </a>
            </li>
            <li>
              <NavLink
                exact
                to="/nosotros"
                activeClassName="nav-link-yellow-selected"
                className="nav-phone-act nav-link-yellow"
              >
                Nosotros
              </NavLink>
              <a href="/nosotros" className="nav-phone-des">
                Nosotros
              </a>
            </li>
            <li>
              <a
                href="#footer"
                className="nav-link-yellow"
                onClick={this.handleToggle}
              >
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
