import React from "react";
import useUrl from "../utils/client";

const ContentIcon = () => {
  const [image1, done1] = useUrl("4hbufy4K09bIqb6AHriJwW");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "10px", width: "10px" }}></div>;
    return <img src={image1} alt="nosotros" />;
  };
  return <>{renderPosts()}</>;
};

export const BannerUrl = ({ title, sub, style }) => {
  return (
    <div style={style}>
      <div className={`banner-url ${title}${sub}`}>
        <div className="banner-url-flex">
          <div className="banner-url-relative">
            <h1 className="banner-url-title before-line">{title}</h1>
            <h1 className="banner-url-sub">{sub}</h1>
          </div>
          <div className="banner-url-relative-nosotros">
            <div className="banner-asdf">
              <div className="banner-text-nosotros">
                <h1 className="banner-url-title-nosotros">
                  ¿POR QUÉ NOSOTROS?
                </h1>
                <p className="banner-url-sub-nosotros">
                  Somos una empresa que brinda soluciones integrales e
                  innovadoras para las diferentes activaciones que desarrolla tu
                  compañia, siempre enfocado a ser tú aliado estrategico.
                </p>
                <p className="banner-url-sub-nosotros">
                  Estamos preparados para atenderte de manera personalizada,
                  entregando la mejor asesoría, soluciones a tus requerimientos
                  y sobre todo brindandote un servicio de excelencia.
                </p>
              </div>
              <div className="banner-image-nosotros">
                <ContentIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const BannerUrlTendencia = ({ title, sub, style }) => {
  return (
    <div style={style}>
      <div className={`banner-url ${title}${sub}`}>
        <div className="banner-url-flex">
          <div className="banner-url-relative">
            <h1 className="banner-url-title before-line">{title}</h1>
            <h1 className="banner-url-sub">{sub}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
export const BannerUrlMerch = ({ title, sub, style }) => {
  return (
    <div style={style}>
      <div className={`banner-url ${title}${sub}`}>
        <div className="banner-url-flex">
          <div className="banner-url-relative">
            <h1 className="banner-url-title">{title}</h1>
            <h1 className="banner-url-sub before-line">{sub}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NewBanner = ({ title, sub, style }) => {
  return (
    <div style={style}>
      <div className={`banner-url ${title}${sub}`}>
        <div className="banner-url-flex">
          <div className="banner-url-relative-tendencia">
            <img src="/tendencia-title.png" alt="tendencia-title"/>
          </div>
        </div>
      </div>
    </div>
  );
};