import React from "react";
import { ReactSlick, CarouselSlick } from "../components/ReactSlick";
import useUrl from "../utils/client";

export const SlideMerchCorp = () => {
  const [src1, ls1] = useUrl("6Hk3wO80hgZu4UWw5OZ0oe");
  const [src2, ls2] = useUrl("31WJaXyPYYxVxk4FnARiMS");
  const [src3, ls3] = useUrl("6GR3pNUS05bFsXGvXPenIA");
  const [src4, ls4] = useUrl("3CQlFHAes66EvN1g0qZxY7");
  const [src5, ls5] = useUrl("uCMYfz5KrtV0in6bCH0bG");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideMerchPrem = () => {
  const [src1, ls1] = useUrl("5ltc5OMQuHbwxqp6hZiRqg");
  const [src2, ls2] = useUrl("z5OewRC4HxGSk72mkGesT");
  const [src3, ls3] = useUrl("5EHJJlFWvRXhSFY0YTl0cX");
  const [src4, ls4] = useUrl("2rQeE5dTSmWa0TWdMD2n0K");
  const [src5, ls5] = useUrl("7ds50LJ69sRDRV9v1qalz0");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideMerchText = () => {
  const [src1, ls1] = useUrl("4FHDHaMpHxc9wMabECaCPU");
  const [src2, ls2] = useUrl("4VdhXyEszSk61jcd7GLj6y");
  const [src3, ls3] = useUrl("5u1uPMKph8VopjngKYhU0H");
  const [src4, ls4] = useUrl("6ukbOamOSnnKPpSvyhagBz");
  const [src5, ls5] = useUrl("2GukDyItriLRai9aLOShl9");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideVisualBranding = () => {
  const [src1, ls1] = useUrl("6iQhS7zHVx2WYKDbnlTzaE");
  const [src2, ls2] = useUrl("5TY1fSLsDX8XwmF9mF14BP");
  const [src3, ls3] = useUrl("69g8mdGLY0ngXI6GTbrt5o");
  const [src4, ls4] = useUrl("dyHeUJtVRmZQ1rgpFdR0J");
  const [src5, ls5] = useUrl("AFMCUTeBQqoffYkJTJ5BQ");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideVisualEstructuras = () => {
  const [src1, ls1] = useUrl("19j4VbagmlZG3GYOQ1U0hh");
  const [src2, ls2] = useUrl("6mPgZpD9HGPBj65TTuG12N");
  const [src3, ls3] = useUrl("4SDIfogasJmhSictan4GTx");
  const [src4, ls4] = useUrl("71QdjqnjUCWkV5Qk7b6wts");
  const [src5, ls5] = useUrl("3XyNdd10vsbJEf2tsN14Wi");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideVisualImplementaciones = () => {
  const [src1, ls1] = useUrl("603x1CKAFFx5n7aeIniZjU");
  const [src2, ls2] = useUrl("5EOxDy6k7fQteH4dgiQW7t");
  const [src3, ls3] = useUrl("3aqygQgpbOS6juobW9fDHB");
  const [src4, ls4] = useUrl("2IRA7yyMFndx9VIUkpOqUE");
  const [src5, ls5] = useUrl("qX8nud0xy6H40p2fN7ezd");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideOffsetComercial = () => {
  const [src1, ls1] = useUrl("4gpFkcFIVCxQhxJbupopYf");
  const [src2, ls2] = useUrl("67Pqr4rgNyGBIqqvbQkBQb");
  const [src3, ls3] = useUrl("3vJ5bd1gbTWd25S6Wi6qhG");
  const [src4, ls4] = useUrl("6mWhttGFyogxpclhdZx422");
  const [src5, ls5] = useUrl("5YqejmEOJs7WlGv0DfZO13");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideOffsetEditorial = () => {
  const [src1, ls1] = useUrl("3PfV7G7DWvboL178ZjySo1");
  const [src2, ls2] = useUrl("24nTp3ufvC1m6GNZ1HTsTq");
  const [src3, ls3] = useUrl("4bx4Rqj1CIXH3Ji8Vs7K3F");
  const [src4, ls4] = useUrl("4ukIKj6F9TQOkEglC6vtyM");
  const [src5, ls5] = useUrl("3LkW037oAujb12PUVtgcLh");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const SlideOffsetEmpaques = () => {
  const [src1, ls1] = useUrl("1E4LPGXnVsvxuVwU1NdM9B");
  const [src2, ls2] = useUrl("37kCX5r7NixNQVO2MiBbLY");
  const [src3, ls3] = useUrl("3HNRjPtoZbKFTlAlAgSozl");
  const [src4, ls4] = useUrl("1Z4MBbbEn4MXdyJROytlxW");
  const [src5, ls5] = useUrl("3o8Mdi7aO1wvSu8K9IGaua");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3 || ls4 || ls5)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return (
      <ReactSlick
        image1={src1}
        image2={src2}
        image3={src3}
        image4={src4}
        image5={src5}
      />
    );
  };
  return <div className="posts">{renderPosts()}</div>;
};

export const CarouselTendencia1 = () => {
  const [src1, ls1] = useUrl("400NPo2oNtnPFJi0QH3xFK");
  const [src2, ls2] = useUrl("1xCl0KuofLCdIPrM9hWcpD");
  const [src3, ls3] = useUrl("7vgCtpZJmDkT8sZzO9Q6Ps");
  const renderPosts = () => {
    if (ls1 || ls2 || ls3)
      return <div style={{ height: "300px", width: "300px" }}></div>;
    return <CarouselSlick image1={src1} image2={src2} image3={src3} />;
  };
  return <div className="lastSlide1">{renderPosts()}</div>;
};