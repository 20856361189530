import React from 'react';

const Popup = (props) => {
    return (
        <div className={props.state.isOpen ? "pop-bg-modal pop-visible" : "pop-bg-modal"}>
            <div className="pop-modal-contents" ref={props.node} >
                <div className="close" onClick={props.handleToggle}>+</div>
                <h1 id="calcular-title">Solicita tu presupuesto</h1>
                <form action="https://send.pageclip.co/DFqKgnILm4FifULEW4voaW3JFxnV5yly/presupuesto" className="flex-form" method="post">
                    <div className="flex-form-even">
                        <input name="Nombre" className="flex-left" type="text" placeholder="* Nombre" required/>
                        <input name="Apellido" className="flex-right" type="text" placeholder="* Apellido" required />
                    </div>
                    <div className="flex-form-even">
                        <input name="Telefono" className="flex-left" type="tel" pattern="[0-9]{9}" placeholder="* Teléfono" required />
                        <input name="Correo" className="flex-right" type="email" placeholder="* Correo" required />
                    </div>
                    <div className="flex-form-even">
                        <input name="Empresa" className="flex-left" type="text" placeholder="* Empresa"  required/>
                        <input name="RUC" className="flex-right" type="text" placeholder="* R.U.C" required />
                    </div>
                    <div className="flex-form-even">
                        <div className="flex-label-even flex-label-left">
                            <label htmlFor="username">* Unidades de negocio</label>
                            <div className="container-select">
                                <select id="user-unidad" name="Unidad" required>
                                    <option value=""
                                        hidden
                                    ></option>
                                    <option value="Merchandising">Merchandising</option>
                                    <option value="Visual">Visual</option>
                                    <option value="Offset">Offset</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex-label-even flex-label-right">
                            <label htmlFor="username">* Sectores de negocio</label>
                            <div  className="container-select">
                                <select id="user-sector" name="Sector" required>
                                    <option value=""
                                        hidden
                                    ></option>
                                    <option value="Alimento y bebidas">Alimento y bebidas</option>
                                    <option value="Productos de belleza">Productos de belleza</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Editorial">Editorial</option>
                                    <option value="Banca y seguros">Banca y seguros</option>
                                    <option value="Farmacia y laboratorio">Farmacia y laboratorio</option>
                                    <option value="Agroindustria">Agroindustria</option>
                                    <option value="Telecomunicaciones">Telecomunicaciones</option>
                                    <option value="Moda y accesorios">Moda y accesorios</option>
                                    <option value="Moda y accesorios">Cuidado personal</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <textarea type="text" id="story" name="Comentario"
                        rows="5" placeholder="*Solicitud de cotización" required></textarea>
                    <div className="button-bg"><button type="submit" className="pop-bg-button pageclip-form__submit">Enviar</button></div>
                    <div className="flex-form-txt">
                        <p>Email: ventas@popup.pe</p>
                        <p>Teléfono: 01 687 1770</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Popup
