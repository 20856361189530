import React, { Component } from 'react'
import Popup from './Popup';

export default class PopState extends Component {

    state = {
        isOpen: false
    };
    handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
   
    

    render() {
        return (
            <>
                <div className="presupuesto-popup" onClick={this.handleToggle}>
                    <div className="popup-circle" id="pop-up-button">
                        <p className="popup-circle-txt pop-big">SOLICITA</p>
                        <p className="popup-circle-txt pop-small">TU PRESUPUESTO</p>
                    </div>
                </div>
                <Popup handleToggle={this.handleToggle} state={this.state} node={this.node} /> 
                  
            </>
        )
    }
}
