import React, { useEffect } from "react";
import UnidadNegocio from "../components/UnidadNegocio";
import Footer from "../components/Footer";
import { BannerUrlTendencia } from "../components/BannerUrl";
import { notify, clearWaitingQueue } from "../components/Notification";
import Layout from "../components/Layout";
import PopState from "../components/PopState";
import { GalleryVisual } from "../components/ContentGallery";
import useUrl from "../utils/client";
import {
  SlideVisualBranding,
  SlideVisualEstructuras,
  SlideVisualImplementaciones,
} from "../components/ContentSlide";

const VisualUnidades = () => {
  const [image1, done1] = useUrl("QP8OZmzqwy7cgBvitn2xc");
  const [image2, done2] = useUrl("5UgbBVus8X5E8POKjjZ2AO");
  const renderPosts = () => {
    if (done1 || done2)
      return <div style={{ width: "100%", height: "500px" }}></div>;
    return (
      <>
        <UnidadNegocio title="merchandising" src={image1} />
        <UnidadNegocio title="offset" src={image2} />
      </>
    );
  };
  return <>{renderPosts()}</>;
};

const ContentBannerUrl = () => {
  const [url, isLoading] = useUrl("1PxvZH9X1fQhT1LRLhIBgJ");
  const renderPosts = () => {
    if (isLoading)
      return <div style={{ width: "80vw", height: "100vh" }}></div>;
    return (
      <BannerUrlTendencia
        title="Visual"
        sub=""
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

const Visual = () => {
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });

  return (
    <>
      <Layout
        pageMeta={{
          title: "Visual",
          keywords: ["pop up", "marketing", "visual"],
        }}
      />
      <ContentBannerUrl />
      <div className="units-contain">
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">BRANDING</h1>
            <SlideVisualBranding />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">BRANDING</h1>
            <p>
              Desarrollamos ambientes que resalten tu marca y posicionen tus
              productos.
            </p>
            <p>
              Ofrecemos un sin número de elementos orientadas a resaltar tu
              imagen.
            </p>
            <ul>
              <li>Banner</li>
              <li>Exhibidores</li>
              <li>Corpóreos</li>
              <li>Display</li>
              <li>Viniles</li>
              <li>Señaléticas</li>
            </ul>
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right unit-left">
          <div className="unit-right-text">
            <h1 className="unit-h1">ESTRUCTURAS</h1>
            <p>
              Ayudamos a solucionar de una forma creativa todas tus necesidades
              de estructuras publicitarias.
            </p>
            <p>Ofrecemos un sin número de estructuras.</p>
            <ul>
              <li>Mobiliario</li>
              <li>Mesas</li>
              <li>Bastidores</li>
              <li>Cabeceras</li>
              <li>Islas</li>
              <li>Tottem</li>
              <li>Cajas de luz</li>
            </ul>
          </div>
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">ESTRUCTURAS</h1>
            <SlideVisualEstructuras />
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">IMPLEMENTACIONES</h1>
            <SlideVisualImplementaciones />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">IMPLEMENTACIONES</h1>
            <p>
              Contamos con un staff de instaladores capacitados para resolver e
              instalar tus requerimientos. Con capacidad de atender de forma
              simultanea en Lima y Provincia
            </p>
            <p>Orientados en generar experiencias de compra inolvidables.</p>
            <ul>
              <li>Instalaciones</li>
            </ul>
          </div>
        </div>
      </div>
      <section>
        <GalleryVisual />
        <div className="section-unidades-negocio">
          <div className="section-flex-unidades">
            <VisualUnidades />
          </div>
        </div>
      </section>
      <Footer />
      <PopState />
    </>
  );
};

export default Visual;
