import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function Gallery({ ...props }) {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });


    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <>
            <div className={`section-galery-${props.title}`} style={props.style}>
                <div className="relative-galery">
                    <div className="galery-portrait-text">
                        <div id="portrait-text">
                            <h1>GALERÍA</h1>
                            <h2>{props.title}</h2>
                        </div>
                    </div>
                    <div className="galery-flex">
                        <div id="galery-text">
                            <h1>GALERÍA</h1>
                            <h2>{props.title}</h2>
                        </div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(1)} ><img src={props.img1} alt="gallery 1" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(2)} ><img src={props.img2} alt="gallery 2" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(3)} ><img src={props.img3} alt="gallery 3" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(4)}><img src={props.img4} alt="gallery 4" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(5)}><img src={props.img5} alt="gallery 5" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(6)}><img src={props.img6} alt="gallery 6" /></div>
                        <div className="gallery-after-before" onClick={() => openLightboxOnSlide(7)}><img src={props.img7} alt="gallery 7" /></div>
                    </div>
                </div>
            </div>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={[
                    props.img1,
                    props.img2,
                    props.img3,
                    props.img4,
                    props.img5,
                    props.img6,
                    props.img7
                ]}
                slide={lightboxController.slide}
                initialAnimation="scale-in-long"
                slideChangeAnimation="scale-in"
            />
        </>
    );
}
export default Gallery; 