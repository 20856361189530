import React, { useEffect } from "react";
import Footer from "../components/Footer";
import { BannerUrl } from "../components/BannerUrl";
import { notify, clearWaitingQueue } from "../components/Notification";
import Layout from "../components/Layout";
import useUrl from "../utils/client";

const ContentBannerUrl = () => {
  const [image, isLoading] = useUrl("5io0Xo6vRvUnopQarUnNri");
  const renderPosts = () => {
    if (isLoading)
      return <div style={{ width: "80vw", height: "100vh" }}></div>;
    return (
      <BannerUrl
        title="Nosotros"
        sub=""
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

const Nosotros = () => {
  const [image1, done1] = useUrl("1wypNcWPlAQ42BgmAfgIZm");
  const [image2, done2] = useUrl("76EHV0xEXpnZWsq9ejwCJ2");
  const [image3, done3] = useUrl("nmu5tYZQngiAyy01qVdyZ");
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });
  const renderPosts = () => {
    if (done1 || done2 || done3)
      return <div style={{ width: "100%", height: "100vh" }}></div>;
    return (
      <>
        <Layout
          pageMeta={{
            title: "Nosotros",
            keywords: ["pop up", "marketing", "nosotros"],
          }}
        />
        <ContentBannerUrl />
        <section>
          <div className="units-contain">
            <div className="unit-right nosotros">
              <div className="unit-right-img nosotros">
                <h1 className="unit-h1-phone nosotros">¿POR QUÉ NOSOTROS?</h1>
                <div className="picture-nosotros-before">
                  <img
                    src={image1}
                    alt="merchandising"
                    className="picture-before"
                  />
                </div>
              </div>
              <div className="unit-right-text">
                <h1 className="unit-h1 nosotros">¿POR QUÉ NOSOTROS?</h1>
                <p>
                  Somos una empresa que brinda soluciones integrales e
                  innovadoras para las diferentes activaciones que desarrolla tu
                  compañia, siempre enfocado a ser tú aliado estrategico.
                  Estamos preparados para atenderte de manera personalizada,
                  entregando la mejor asesoría, soluciones a tus requerimientos
                  y sobre todo brindandote un servicio de excelencia.
                </p>
              </div>
            </div>
            <hr className="grey-hr nosotros"></hr>
            <div className="unit-right unit-nosotros-phone zio-charge001">
              <h1 className="unit-h1-phone nosotros">MISIÓN</h1>
              <div className="unit-right-img nosotros nosotros-right-before">
                <img src={image2} alt="vision" />
              </div>
              <div className="unit-right-text">
                <h1 className="unit-h1 nosotros">MISIÓN</h1>
                <p>
                  Entregar la mejor asesoría y acompañamiento para la ejecución
                  de los proyectos de nuestros clientes y aliados.
                </p>
              </div>
            </div>
            <hr className="grey-hr nosotros"></hr>
            <div className="unit-right unit-left unit-nosotros-phone">
              <h1 className="unit-h1-phone nosotros">VISIÓN</h1>
              <div className="unit-right-text disable-phone">
                <h1 className="unit-h1 nosotros">VISIÓN</h1>
                <p>
                  Ser una transnacional líder en innovación, desarrollo y
                  ejecución con lineamientos enfocados a tener alto nivel de
                  excelencia y estándar de calidad.
                </p>
              </div>
              <div className="unit-right-img nosotros nosotros-left-before">
                <img src={image3} alt="mision" />
              </div>
              <div className="unit-right-phone">
                <p>
                  Ser una transnacional líder en innovación, desarrollo y
                  ejecución con lineamientos enfocados a tener alto nivel de
                  excelencia y estándar de calidad.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
  return <>{renderPosts()}</>;
};

export default Nosotros;
