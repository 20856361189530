import React from "react";
import Socialcontact from "./Socialcontact";
import Changestate from "./Changestate";
import useUrl from "../utils/client";

const Footer = () => {
  const [image1, done1] = useUrl("4uKPSffgGRPmvcjOphFbvP");
  const renderPosts = () => {
    if (done1) return <div style={{ height: "500px", width: "100%" }}></div>;
    return (
      <>
        <footer>
          <div id="footer">
            <div className="flex-footer">
              <div className="logo">
                <a href="/">
                  <img src={image1} alt="Pop up" />
                </a>
              </div>
              <div className="email-footer flex-item">
                <form
                  action="https://send.pageclip.co/DFqKgnILm4FifULEW4voaW3JFxnV5yly/email"
                  method="post"
                >
                  <h1>Se parte de nuestra familia</h1>
                  <div className="input-group-flex">
                    <div className="input-group">
                      <Changestate />
                      <label htmlFor="familia">Escribe tu e-mail</label>
                    </div>
                    <button type="submit" className="pageclip-form__submit">
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
              <Socialcontact />
              <div className="information">
                <div className="information-grid">
                  <div className="info">
                    <div className="info-title">Dirección</div>
                    <div className="info-detail">
                      Jr. Jorge Chávez 732 - Lima 05
                    </div>
                  </div>
                  <div className="info">
                    <div className="info-title">Email</div>
                    <div className="info-detail">ventas@popup.pe</div>
                  </div>
                  <div className="info">
                    <div className="info-title">Horarios</div>
                    <div className="info-detail">De Lunes a viernes</div>
                    <div className="info-detail">8 a.m - 6:30 p.m</div>
                    <div className="info-detail">Sábado</div>
                    <div className="info-detail">8 a.m - 1 p.m</div>
                  </div>
                  <div className="info">
                    <div className="info-title">Teléfono</div>
                    <div className="info-detail">01 687 1770</div>
                    <div className="info-detail">+51 990 016 463</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  };
  return <>{renderPosts()}</>;
};

export default Footer;
