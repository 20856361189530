import React from "react";
import Gallery from "./Galeria";
import useUrl from "../utils/client";

export const GalleryHome = () => {
  const [banner, done] = useUrl("AGf7LmxJvRlQxTvqPyWw3");
  const [sector1, done1] = useUrl("5BK3BeDWj2FCsPZiZSUeaU");
  const [sector2, done2] = useUrl("5mARz9VrX7atUHfSs5X03n");
  const [sector3, done3] = useUrl("VUpVGAZYMuaa5spidAS9k");
  const [sector4, done4] = useUrl("24eZ1jSb64h0CQYFPIszFi");
  const [sector5, done5] = useUrl("KaLK02H8UrVvuMrcNUGEw");
  const [sector6, done6] = useUrl("4DqPEVsr3F5MwaWHxjJljN");
  const [sector7, done7] = useUrl("1eK42IEZE8g06H2BgljpbE");
  const renderPosts = () => {
    if (done1 || done2 || done3 || done4 || done5 || done6 || done7 || done)
      return <div style={{ height: "200px", width: "100%" }}></div>;
    return (
      <Gallery
        title="DE TRABAJOS"
        img1={sector1}
        img2={sector2}
        img3={sector3}
        img4={sector4}
        img5={sector5}
        img6={sector6}
        img7={sector7}
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

export const GalleryMerch = () => {
  const [banner, done] = useUrl("6erzhN9cjbSiCn7XV9L9ek");
  const [sector1, done1] = useUrl("2mvOeU8AKTcdtjpAGDpQrW");
  const [sector2, done2] = useUrl("13EHXPL1XEiL40cPgttqqK");
  const [sector3, done3] = useUrl("239DvN8b6DzmthbWxHV0pK");
  const [sector4, done4] = useUrl("7tH6up7fm8eXq4XRcWx3xR");
  const [sector5, done5] = useUrl("FKkCuAasbvBmHgnAeoYcB");
  const [sector6, done6] = useUrl("3uOQGSOn679C5zaetpsay7");
  const [sector7, done7] = useUrl("7jRY40YIoBlceXeUnS7GaN");
  const renderPosts = () => {
    if (done1 || done2 || done3 || done4 || done5 || done6 || done7 || done)
      return <div style={{ height: "200px", width: "100%" }}></div>;
    return (
      <Gallery
        title="MERCHANDISING"
        img1={sector1}
        img2={sector2}
        img3={sector3}
        img4={sector4}
        img5={sector5}
        img6={sector6}
        img7={sector7}
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

export const GalleryOffset = () => {
  const [banner, done] = useUrl("mNVlRJavUM0VpuuoLbViT");
  const [sector1, done1] = useUrl("14ZvlWDbQz3GDK2WAdpync");
  const [sector2, done2] = useUrl("7bq1HHHUYnjyPcCeMPhGIH");
  const [sector3, done3] = useUrl("7cSveeBppWLPvvpJB9f21");
  const [sector4, done4] = useUrl("2aiSYeqWGbxHsja6j8OS6e");
  const [sector5, done5] = useUrl("7C3ZVa4zREqeFgl4kshKkl");
  const [sector6, done6] = useUrl("7CtwNbwQVXkT8MMnREaBNX");
  const [sector7, done7] = useUrl("7qvHuKQUx39rhoXc0ic6Ji");
  const renderPosts = () => {
    if (done1 || done2 || done3 || done4 || done5 || done6 || done7 || done)
      return <div style={{ height: "200px", width: "100%" }}></div>;
    return (
      <Gallery
        title="OFFSET"
        img1={sector1}
        img2={sector2}
        img3={sector3}
        img4={sector4}
        img5={sector5}
        img6={sector6}
        img7={sector7}
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

export const GalleryVisual = () => {
  const [banner, done] = useUrl("123r0gKNAUQSd94OARUFzi");
  const [sector1, done1] = useUrl("210M7zNiUHRjmRmA2fm2N4");
  const [sector2, done2] = useUrl("5svbDtiwip88ZCmVZaibyx");
  const [sector3, done3] = useUrl("1PgVoWz7O3LOwZtEA3CKum");
  const [sector4, done4] = useUrl("1d2i5U4e7chRxzF2HQdco3");
  const [sector5, done5] = useUrl("5AXhbMH6xxvyShcMOeUW3n");
  const [sector6, done6] = useUrl("2N2MPtkRiCDgWeGZMavbK9");
  const [sector7, done7] = useUrl("xkaD253OOMWvVJpR3mmQ3");
  const renderPosts = () => {
    if (done1 || done2 || done3 || done4 || done5 || done6 || done7 || done)
      return <div style={{ height: "200px", width: "100%" }}></div>;
    return (
      <Gallery
        title="VISUAL"
        img1={sector1}
        img2={sector2}
        img3={sector3}
        img4={sector4}
        img5={sector5}
        img6={sector6}
        img7={sector7}
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};
