import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Autoplay from "../components/Autoplay";
import UnidadNegocio from "../components/UnidadNegocio";
import BannerTendencia from "../components/BannerTendencia";
import SectoresNegocio from "../components/SectoresNegocio";
import Footer from "../components/Footer";
import Social from "../components/Social";
import PopState from "../components/PopState";
import { GalleryHome } from "../components/ContentGallery";
import { notify, clearWaitingQueue } from "../components/Notification";
import useUrl from "../utils/client";

const HomeUnidades = () => {
  const [image1, done1] = useUrl("QP8OZmzqwy7cgBvitn2xc");
  const [image2, done2] = useUrl("4Ueh5D1vD5sV0pRRQm1Wf9");
  const [image3, done3] = useUrl("5UgbBVus8X5E8POKjjZ2AO");

  const renderPosts = () => {
    if (done1 || done2 || done3)
      return <div style={{ width: "100%", height: "500px" }}></div>;
    return (
      <>
        <UnidadNegocio title="merchandising" src={image1} />
        <UnidadNegocio title="visual" src={image2} />
        <UnidadNegocio title="offset" src={image3} />
      </>
    );
  };
  return <>{renderPosts()}</>;
};

const Home = () => {
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });
  return (
    <>
      <Helmet>
        <title>Pop up - Marketing Integral</title>
        <meta
          name="description"
          content="Soluciones integrales e innovadoras para las diferentes activaciones que desarrolla tu compañia."
        />
        <meta name="keywords" content="popup, marketing, offset" />
      </Helmet>
      <Autoplay />
      <Social />
      <section>
        <div className="section-unidades-negocio">
          <h1>Unidades de negocio</h1>
          <div className="section-flex-unidades">
            <HomeUnidades />
          </div>
        </div>
        <BannerTendencia/>
        <SectoresNegocio />
        <GalleryHome />
      </section>
      <Footer />
      <PopState />
    </>
  );
};

export default Home;
