import React, { useEffect } from "react";
import UnidadNegocio from "../components/UnidadNegocio";
import Footer from "../components/Footer";
import { BannerUrlTendencia } from "../components/BannerUrl";
import { notify, clearWaitingQueue } from "../components/Notification";
import Layout from "../components/Layout";
import PopState from "../components/PopState";
import useUrl from "../utils/client";
import { GalleryOffset } from "../components/ContentGallery";
import {
  SlideOffsetComercial,
  SlideOffsetEditorial,
  SlideOffsetEmpaques,
} from "../components/ContentSlide";

const OffsetUnidades = () => {
  const [image1, done1] = useUrl("4Ueh5D1vD5sV0pRRQm1Wf9");
  const [image2, done2] = useUrl("QP8OZmzqwy7cgBvitn2xc");

  const renderPosts = () => {
    if (done1 || done2)
      return <div style={{ width: "100%", height: "500px" }}></div>;
    return (
      <>
        <UnidadNegocio title="visual" src={image1} />
        <UnidadNegocio title="merchandising" src={image2} />
      </>
    );
  };
  return <>{renderPosts()}</>;
};

const ContentBannerUrl = () => {
  const [url, isLoading] = useUrl("1pUOMHE3ndk4oQCo4sisrm");
  const renderPosts = () => {
    if (isLoading)
      return <div style={{ width: "80vw", height: "100vh" }}></div>;
    return (
      <BannerUrlTendencia
        title="Offset"
        sub=""
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

const Offset = () => {
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });

  return (
    <>
      <Layout
        pageMeta={{
          title: "Offset",
          keywords: ["pop up", "marketing", "offset"],
        }}
      />
      <ContentBannerUrl />
      <div className="units-contain">
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">EMPAQUES</h1>
            <SlideOffsetEmpaques />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">EMPAQUES</h1>
            <p>
              Ofrecemos empaques de línea y promocionales en cartón; cajas,
              estuches, exhibidores, display con acabados de impacto.
            </p>
            <p>Cartones corrugados y micro corrugados.</p>
            <ul>
              <li>Cajas</li>
              <li>Exhibidores</li>
              <li>Display</li>
              <li>Microcorrugados</li>
            </ul>
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right unit-left">
          <div className="unit-right-text">
            <h1 className="unit-h1">EDITORIAL</h1>
            <p>
              Buscamos que resaltes, es por ello que ofrecemos impresiones que
              transmita claramente tu mensaje.
            </p>
            <ul>
              <li>Anillados</li>
              <li>Educativos</li>
              <li>Comerciales</li>
              <li>Publicaciones</li>
              <li>Revistas</li>
              <li>Cuentos</li>
              <li>Memorias</li>
            </ul>
          </div>
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">EDITORIAL</h1>
            <SlideOffsetEditorial />
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">COMERCIAL</h1>
            <SlideOffsetComercial />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">COMERCIAL</h1>
            <p>
              Sumando a tus esfuerzos de Marketing, te brindamos propuestas que
              facilitaran la comunicación de tu producto.
            </p>
            <ul>
              <li>Dípticos</li>
              <li>Trípticos</li>
              <li>Cuadrípticos</li>
              <li>Calendarios</li>
              <li>Recetarios</li>
              <li>Jalavistas</li>
              <li>Colgantes</li>
              <li>Banderines</li>
              <li>Flanger</li>
            </ul>
          </div>
        </div>
      </div>
      <section>
        <GalleryOffset />
        <div className="section-unidades-negocio">
          <div className="section-flex-unidades">
            <OffsetUnidades />
          </div>
        </div>
      </section>
      <Footer />
      <PopState />
    </>
  );
};

export default Offset;
