import React, { Component } from "react";
import Popup from "./Popup";
import { CarouselTendencia1 } from "./ContentSlide";

export default class PopTendencia extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <>
        <section>
          <div className="tendencia-wrapper-slide">
            <div className="tendencia-height">
              <CarouselTendencia1 />
            </div>
          </div>
          <div className="tendencia-solicita-wrapper">
            <div className="tendencia-solicita-prep" onClick={this.handleToggle}>
              <span className="tendencia-solicita-prep-up">SOLICITA</span>
              <span className="tendencia-solicita-prep-bottom">TU PRESUPUESTO</span>
            </div>
          </div>
        </section>
        <Popup handleToggle={this.handleToggle} state={this.state} />
      </>
    );
  }
}
