import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "../Slider.css"; //custom design import 'react-awesome-slider/dist/styles.css'
import useUrl from "../utils/client";
import Socialcontact from "./Socialcontact";
import { isMobile } from "react-device-detect";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const AutoPlay = () => {
  const [image1, done1] = useUrl("tdw3V9mt2uxHHcmacJikx");
  const [image2, done2] = useUrl("7vtleITs2G5MdGc3EADYl7");
  const [image3, done3] = useUrl("x1HhkpLMa1J9J38EtT4ug");
  const [image4, done4] = useUrl("3FyJJ69Q1uE6KyVeC45Tu8");
  const [image5, done5] = useUrl("4QxpiLuzkXESurqmiKtj8Q");
  const [image6, done6] = useUrl("62Pi3zT3bfmqtd91iSRQ3I");
  const renderPosts = () => {
    if (done1 || done2 || done3 || done4 || done5 || done6)
      return <div style={{ width: "80vw", height: "100vh" }}></div>;
    if (isMobile) {
      return (
        <AutoplaySlider
          play={true}
          cancelOnInteraction={true}
          interval={2000}
          buttons={false}
          organicArrows={false}
        >
          <div data-src={image1} data-alt="merchandising">
            <div>
              <div className="caption--1hwV">
                <h1 className="caption-p caption-merch">Merchan</h1>
                <h1 className="caption-p caption-merch underline-yell">
                  Dising
                </h1>
              </div>
              <div className="social--1hwV">
                <Socialcontact />
              </div>
            </div>
          </div>
          <div data-src={image2} data-alt="offset">
            <div className="flex-caption">
              <div className="caption--1hwV">
                <h1 className="caption-p underline-yell">Offset</h1>
              </div>
              <div className="social--1hwV">
                <Socialcontact />
              </div>
            </div>
          </div>
          <div data-src={image3} data-alt="visual">
            <div>
              <div className="caption--1hwV">
                <h1 className="caption-p underline-yell">Visual</h1>
              </div>
              <div className="social--1hwV">
                <Socialcontact />
              </div>
            </div>
          </div>
        </AutoplaySlider>
      );
    }
    return (
      <AutoplaySlider
        play={true}
        cancelOnInteraction={true}
        interval={2000}
        buttons={false}
        organicArrows={false}
      >
        <div data-src={image4} data-alt="merchandising">
          <div>
            <div className="caption--1hwV">
              <h1 className="caption-p caption-merch">Merchan</h1>
              <h1 className="caption-p caption-merch underline-yell">Dising</h1>
            </div>
            <div className="social--1hwV">
              <Socialcontact />
            </div>
          </div>
        </div>
        <div data-src={image5} data-alt="offset">
          <div className="flex-caption">
            <div className="caption--1hwV">
              <h1 className="caption-p underline-yell">Offset</h1>
            </div>
            <div className="social--1hwV">
              <Socialcontact />
            </div>
          </div>
        </div>
        <div data-src={image6} data-alt="visual">
          <div>
            <div className="caption--1hwV">
              <h1 className="caption-p underline-yell">Visual</h1>
            </div>
            <div className="social--1hwV">
              <Socialcontact />
            </div>
          </div>
        </div>
      </AutoplaySlider>
    );
  };
  return <>{renderPosts()}</>;
};

export default AutoPlay;
