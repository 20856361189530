import React from "react";
import useUrl from "../utils/client";

const ContentBannerUrl = () => {
  const [image1, done1] = useUrl("7FE1KLrFrbl1oaR3fCOtxa");
  const [image2, done2] = useUrl("6zNzKMOUnv1wp6WIZrWu4W");
  const renderPosts = () => {
    if (done1 || done2)
      return <div style={{ height: "400px", width: "400px" }}></div>;
    return (
      <div
        className="section-tendencia"
        style={{
          backgroundColor: "#ffdd00",
        }}
      >
        <div className="relative-tendency">
          <div className="tendencia-flex">
            <div className="tendencia-img-wrapper">
              <div className="tendencia-image">
                <img
                  className="tend-obj-back"
                  src={image1}
                  alt="tendencia"
                />
                <img
                  className="tend-obj-front"
                  src="/tendencia-title.png"
                  alt="tendencia"
                />
              </div>
            </div>
            <div className="tendencia-objects">
              <h2> LANZAMOS! </h2>
              <h3> NUESTRA NUEVA LÍNEA </h3>
              <img
                className="tendencia-obj-care"
                src={image2}
                alt="health-care"
              />
              <a href="./tendencia" className="tendencia-object-button">
                CONOCE MÁS
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <>{renderPosts()}</>;
};

function BannerTendencia() {
  return <ContentBannerUrl />;
}

export default BannerTendencia;
