import React from "react";
import useUrl from "../utils/client";

const SectorNegocio = ({ title, logo }) => {
  return (
    <div className="section-unit-sector">
      <img src={logo} alt={title} />
      <h2> {title} </h2>
    </div>
  );
};

const SectoresNegocio = () => {
  const [sector1, done1] = useUrl("3PjrpxMJYMRGBomKIVp3Dz");
  const [sector2, done2] = useUrl("5kjpw61AJXWJ9imnxxwHRJ");
  const [sector3, done3] = useUrl("rRVhWlfpp2nEjLM7uhU9F");
  const [sector4, done4] = useUrl("7CfDyE0BBuTCdq3QZEovpK");
  const [sector5, done5] = useUrl("6cTAcDVZbxbgggXXM94MxP");
  const [sector6, done6] = useUrl("5DKvrpM8cbtSvO2YLfHnSy");
  const [sector7, done7] = useUrl("4zthFDFbRsMfLlie5zkqFk");
  const [sector8, done8] = useUrl("d6UxgWf1mTQxGSGD4mR3J");
  const [sector9, done9] = useUrl("6ligesRog46QmbVCTf286A");
  const [sector10, done10] = useUrl("1OLe2cD40T0U2OEz5n4CkX");
  const renderPosts = () => {
    if (
      done1 ||
      done2 ||
      done3 ||
      done4 ||
      done5 ||
      done6 ||
      done7 ||
      done8 ||
      done9 ||
      done10
    )
      return <div style={{ height: "300px", width: "100%" }}></div>;
    return (
      <div className="section-sectores-negocio">
        <h1>Sectores de negocio</h1>
        <div className="section-flex-sectores">
          <SectorNegocio title="Alimentos y bebidas" logo={sector1} />
          <SectorNegocio title="Productos de belleza" logo={sector2} />
          <SectorNegocio title="Cuidado personal" logo={sector3} />
          <SectorNegocio title="Retail" logo={sector4} />
          <SectorNegocio title="Editorial" logo={sector5} />
          <SectorNegocio title="Banca y seguros" logo={sector6} />
          <SectorNegocio title="Farmacia y laboratorio" logo={sector7} />
          <SectorNegocio title="Agroindustria" logo={sector8} />
          <SectorNegocio title="Telecomunicaciones" logo={sector9} />
          <SectorNegocio title="Moda y accesorios" logo={sector10} />
        </div>
      </div>
    );
  };
  return <>{renderPosts()}</>;
};

export default SectoresNegocio;
