import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Notification.module.css";
import useUrl from "../utils/client";

const Image = () => {
  const [src, isLoading] = useUrl("4jhrjgoUCx5RjQjld31vgE");
  const renderPosts = () => {
    if (isLoading) return <div style={{ height: "80px", width: "75px" }}></div>;
    return (
      <div
        style={{
          backgroundImage: `url(${src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
        className={styles.image}
      ></div>
    );
  };
  return <>{renderPosts()}</>;
};

const Content = () => {
  return (
    <a href="https://catalogo.popup.pe/" className={styles.wrapper} target="_blank" rel="noopener noreferrer">
      <div className={styles.imageWrapper}>
        <Image />
      </div>
      <div className={styles.description}>CATALOGO 2023 MERCHANDISING</div>
    </a>
  );
};

export const notify = () => {
  toast(<Content />, {
    position: "bottom-left",
    autoClose: 6000,
    delay: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const clearWaitingQueue = () => {
  // Easy, right 😎
  toast.clearWaitingQueue();
};
