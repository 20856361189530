import React from 'react';

export default function UnidadNegocio({ title, src }) {
    return (
        <a href={`./${title}`}>
            <div className="section-unit business-box">
                <img className="business-img" src={src} alt={title} />
                <h2> {title} </h2>
            </div>
        </a>
    )
}
