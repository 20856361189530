import React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import Home from "./pages/Home";
import Merchandising from "./pages/Merchandising";
import Offset from "./pages/Offset";
import Visual from "./pages/Visual";
import Tendencia from "./pages/Tendencia";
import Nosotros from "./pages/Nosotros";
import Navbar from "./components/Navbar";
import "./App.css";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/merchandising/" component={Merchandising} />
        <Route exact path="/offset/" component={Offset} />
        <Route exact path="/visual" component={Visual} />
        <Route exact path="/tendencia" component={Tendencia} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route component={Home} />
      </Switch>
      <ToastContainer
        className="mynotification"
        transition={Flip}
        limit={1}
        position="bottom-left"
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
