import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "slick-carousel/slick/slick.css";
import "../Slick.css";
import Slider from "react-slick";
import Carousel from "react-spring-3d-carousel";
import { useRef } from "react";
import { useEffect } from "react";

export const ReactSlick = ({ ...props }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  return (
    <>
      <Slider
        dots={false}
        arrows={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
      >
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(1)}
            className="pretty-slides real"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(2)}
            className="pretty-slides real"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(3)}
            className="pretty-slides real"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image4})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(4)}
            className="pretty-slides real"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image5})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(5)}
            className="pretty-slides real"
          ></div>
        </div>
      </Slider>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[
          props.image1,
          props.image2,
          props.image3,
          props.image4,
          props.image5,
        ]}
        slide={lightboxController.slide}
      />
    </>
  );
};

export const FakeSlick = ({ ...props }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <>
      <Slider
        dots={false}
        arrows={true}
        infinite={true}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
      >
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(1)}
            className="pretty-slides"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(2)}
            className="pretty-slides"
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url(${props.image3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            alt="merchandising"
            onClick={() => openLightboxOnSlide(3)}
            className="pretty-slides"
          ></div>
        </div>
      </Slider>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[props.image1, props.image2, props.image3]}
        slide={lightboxController.slide}
      />
    </>
  );
};

export const CarouselSlick = ({ image1, image2, image3 }) => {
  const node = useRef();
  const [goToSlide, setgoToSlide] = useState(null);

  const slides = [
    {
      key: "1",
      content: <img src={image1} alt="tendencias" />,
    },
    {
      key: "2",
      content: <img src={image3} alt="tendencias" />,
    },
    {
      key: "3",
      content: <img src={image2} alt="tendencias" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setgoToSlide(index) };
  });
  useEffect(() => {
    node.current.children[0].children[0].onclick = console.log('hello')
  }, [])

  return (
    <>
      <div
        className='myslick'
        ref={node}
      >
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
        />
      </div>
    </>
  );
};
