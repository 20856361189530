import React, { Component } from 'react'

export default class Changestate extends Component {

    constructor() {
        super();
        this.state = {
            inputValue: '',
            fieldActive: false
        }

        this.updateInputValue = this.updateInputValue.bind(this);
        this.activateField = this.activateField.bind(this);
        this.disableFocus = this.disableFocus.bind(this);
    }
    activateField() {
        this.setState({
            fieldActivate: false
        })
    }
    disableFocus(e) {
        if (e.target.value === "") {
            this.setState({
                fieldActivate: false
            })
        }
    }
    updateInputValue(e) {
        this.setState({
            inputValue: e.target.value,
        });
        this.activateField(e);
        e.preventDefault();
    }

    render() {
        return (
            <input
                type="email"
                name="email"
                id="familia"
                className={`form-control${this.state.inputValue? " has-value" : ""}`}
                value={this.state.inputValue}
                onFocus={this.activateField}
                onBlur={this.disableField}
                onChange={this.updateInputValue}
                required
            />
        )
    }
}