import React, { useEffect } from "react";
import Footer from "../components/Footer";
import { BannerUrlTendencia } from "../components/BannerUrl";
import Layout from "../components/Layout";
import PopTendencia from "../components/PopTendencia";
import { notify, clearWaitingQueue } from "../components/Notification";
import useUrl from "../utils/client";

const ContentBannerUrl = () => {
  const [image, isLoading] = useUrl("6KgrL9fHl5odEKNngGqQiK");
  const renderPosts = () => {
    if (isLoading)
      return <div style={{ width: "80vw", height: "100vh" }}></div>;
    return (
      <BannerUrlTendencia
        title="Tendencia"
        sub=""
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

const Tendencia = () => {
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });

  return (
    <>
      <Layout
        pageMeta={{
          title: "Tendencia",
          keywords: ["pop up", "marketing", "tendencia"],
        }}
      />
      <ContentBannerUrl />
      <PopTendencia />
      <Footer />
    </>
  );
};

export default Tendencia;
