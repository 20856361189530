import { useState, useEffect } from "react";

const client = require("contentful").createClient({
  // can be visible
  space: "xy2edrnbnx7m",
  accessToken: "6D7UrEDBSqeFrbtHoj2B9EV8lGBkecCNoeZxJ8kgWjA",
});

export default function useUrl(ls) {
  const [location, setPosts] = useState("");
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    client.getEntry(ls).then((item) => {
      setPosts(`https://${item.fields.image.fields.file.url}`);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  return [location, isLoading];
}
