import React, { useEffect } from "react";
import UnidadNegocio from "../components/UnidadNegocio";
import Footer from "../components/Footer";
import { BannerUrlMerch } from "../components/BannerUrl";
import Layout from "../components/Layout";
import PopState from "../components/PopState";
import { GalleryMerch } from "../components/ContentGallery";
import {
  SlideMerchCorp,
  SlideMerchPrem,
  SlideMerchText,
} from "../components/ContentSlide";
import { notify, clearWaitingQueue } from "../components/Notification";
import useUrl from "../utils/client";

const MerchUnidades = () => {
  const [image1, done1] = useUrl("4Ueh5D1vD5sV0pRRQm1Wf9");
  const [image2, done2] = useUrl("5UgbBVus8X5E8POKjjZ2AO");
  const renderPosts = () => {
    if (done1 || done2)
      return <div style={{ width: "100%", height: "500px" }}></div>;
    return (
      <>
        <UnidadNegocio title="visual" src={image1} />
        <UnidadNegocio title="offset" src={image2} />
      </>
    );
  };
  return <>{renderPosts()}</>;
};

const ContentBannerUrl = () => {
  const [url, isLoading] = useUrl("QoEFuuCz6QYR9FOOrjYZY");
  const renderPosts = () => {
    if (isLoading) return <div style={{ width: "80vw", height: "95vh" }}></div>;
    return (
      <BannerUrlMerch
        title="Merchan"
        sub="dising"
        style={{
          backgroundImage: `linear-gradient(rgba(33, 0, 65, 0.616), rgba(48, 0, 63, 0.561)), url(${url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );
  };
  return <>{renderPosts()}</>;
};

const Merchandising = () => {
  useEffect(() => {
    notify();
    clearWaitingQueue();
  });
  return (
    <>
      <Layout
        pageMeta={{
          title: "Merchandising",
          keywords: ["pop up", "marketing", "merchandising"],
        }}
      />
      <ContentBannerUrl />
      <div className="units-contain">
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">CORPORATIVO</h1>
            <SlideMerchCorp />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">CORPORATIVO</h1>
            <p>
              Te ofrecemos un sin número de propuestas orientadas a resaltar tu
              imagen corporativa
            </p>
            <ul>
              <li>Bolígrafos</li>
              <li>M. de Oficina</li>
              <li>Memorias USB</li>
              <li>Resaltadores</li>
              <li>Mug</li>
              <li>Mochilas</li>
              <li>Bolsas</li>
            </ul>
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right unit-left">
          <div className="unit-right-text">
            <h1 className="unit-h1">PREMIUM</h1>
            <p>
              Estamos comprometidos con tus esfuerzos de Marketing y es por ello
              que te ofrecemos una diversidad de regalos premium, que lograra
              causar experiencias y emociones.
            </p>
            <ul>
              <li>Regalos importados</li>
              <li>Kits especiales y dirigidos</li>
              <li>Sets</li>
              <li>Médicos y Farmacéuticos</li>
            </ul>
          </div>
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">PREMIUM</h1>
            <SlideMerchPrem />
          </div>
        </div>
        <hr className="grey-hr"></hr>
        <div className="unit-right">
          <div className="unit-right-img unidades">
            <h1 className="unit-h1-phone">TEXTIL</h1>
            <SlideMerchText />
          </div>
          <div className="unit-right-text">
            <h1 className="unit-h1">TEXTIL</h1>
            <p>
              Con la diversa gama de tejidos y telas que se encuentra, te
              ofrecemos diversas opciones de prendas donde se pueda estampar tu
              marca.
            </p>
            <ul>
              <li>Indumentarias</li>
              <li>Mochilas</li>
              <li>Uniformes</li>
              <li>Bolsas de Tela</li>
              <li>Cartucheras</li>
              <li>Morrales</li>
              <li>Camisas</li>
              <li>Chalecos</li>
            </ul>
          </div>
        </div>
      </div>
      <section>
        <GalleryMerch />
        <div className="section-unidades-negocio">
          <div className="section-flex-unidades">
            <MerchUnidades />
          </div>
        </div>
      </section>
      <Footer />
      <PopState />
    </>
  );
};

export default Merchandising;
